import styled from "styled-components"
import { minMQ, maxMQ } from "./helpers"

export const Container = styled.div`
  /* max-width: ${props => props.theme.sizeFullHD}px; */
  max-width: 100%;
  width: 100%;
  padding-left: ${props => props.theme.paddingMobile};
  padding-right: ${props => props.theme.paddingMobile};
  margin-left: auto;
  margin-right: auto;
  ${minMQ("tablet")} {
    padding-left: ${props => props.theme.paddingTablet};
    padding-right: ${props => props.theme.paddingTablet};
  }
  ${minMQ("desktop")} {
    padding-left: ${props => props.theme.paddingDesktop};
    padding-right: ${props => props.theme.paddingDesktop};
  }
`

export const FullWidthContainer = styled.div`
  /* max-width: ${props => props.theme.sizeFullHD}px; */
  max-width: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

export const WordpressContentContainer = styled.div`
  > *:not(.alignwide):not(.alignfull):not(.alignleft):not(.alignright):not(
      .is-style-wide
    ) {
    /* max-width: ${props => props.theme.sizeFullHD}px; */
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    ${maxMQ("mobile")} {
      width: calc(100% - ((${props => props.theme.paddingMobile}) * 2));
    }

    ${minMQ("tablet")} {
      width: calc((100% / 16) * 10);
    }

    ${minMQ("desktop")} {
      width: calc((100% / 16) * 9);
    }

    ${minMQ("fullhd")} {
      width: calc((100% / 16) * 8.5);
    }
  }

  .alignwide {
    margin-left: auto !important;
    margin-right: auto !important;
    ${maxMQ("mobile")} {
      width: calc(100% - ((${props => props.theme.paddingMobile}) * 2));
    }

    ${minMQ("tablet")} {
      max-width: calc(
        (
            100vw - (${props => props.theme.paddingTablet} * 2) -
              (${props => props.theme.navWidth})
          ) / 16 * 16
      );
    }

    ${minMQ("desktop")} {
      max-width: calc(
        (
            100vw - (${props => props.theme.paddingDesktop} * 2) -
              (${props => props.theme.navWidth})
          ) / 16 * 16
      );
    }

    ${minMQ("fullhd")} {
      max-width: calc(
        (
            (${props => props.theme.sizeFullHD}px) -
              (${props => props.theme.paddingDesktop} * 2)
          ) / 16 * 16
      );
    }

    figcaption {
      width: 100%;
      ${minMQ("fullhd")} {
        width: calc(
          (${props => props.theme.sizeFullHD}px) -
            (${props => props.theme.paddingDesktop} * 2)
        );
      }
    }
  }

  .alignfull {
    ${minMQ("tablet")} {
      margin-left: calc(
        -100vw / 2 + 100% / 2 + (${props => props.theme.navWidth} / 2)
      );
      margin-right: calc(
        -100vw / 2 + 100% / 2 + (${props => props.theme.navWidth} / 2)
      );
      max-width: 100vw;
    }
  }
`

export const Section = styled.section`
  display: ${props => props.$allDisplay};
  flex: ${props => props.$allFlex};
  flex-direction: ${props => props.$allFlexDirection};
  flex-wrap: ${props => props.$allFlexWrap};
  justify-content: ${props => props.$allJustifyContent};
  align-items: ${props => props.$allAlignItems};
  position: ${props => props.$allPosition};
  top: ${props => props.$allTop};
  right: ${props => props.$allRight};
  bottom: ${props => props.$allBottom};
  left: ${props => props.$allLeft};
  z-index: ${props => props.$allZIndex};
  width: ${props => props.$allWidth};
  min-width: ${props => props.$allMinWidth};
  max-width: ${props => props.$allMaxWidth};
  height: ${props => props.$allHeight};
  min-height: ${props => props.$allMinHeight};
  max-height: ${props => props.$allMaxHeight};
  margin: ${props => props.$allMargin};
  margin-top: ${props => props.$allMarginTop};
  margin-right: ${props => props.$allMarginRight};
  margin-bottom: ${props => props.$allMarginBottom};
  margin-left: ${props => props.$allMarginLeft};
  padding: ${props => props.$allPadding};
  padding-top: ${props => props.$allPaddingTop};
  padding-right: ${props => props.$allPaddingRight};
  padding-bottom: ${props => props.$allPaddingBottom};
  padding-left: ${props => props.$allPaddingLeft};
  overflow: ${props => props.$allOverflow};
  background-color: ${props => props.$allBackgroundColor};
  ${maxMQ("touch")} {
    display: ${props => props.$touchDisplay};
    flex: ${props => props.$touchFlex};
    flex-direction: ${props => props.$touchFlexDirection};
    flex-wrap: ${props => props.$touchFlexWrap};
    justify-content: ${props => props.$touchJustifyContent};
    align-items: ${props => props.$touchAlignItems};
    position: ${props => props.$touchPosition};
    top: ${props => props.$touchTop};
    right: ${props => props.$touchRight};
    bottom: ${props => props.$touchBottom};
    left: ${props => props.$touchLeft};
    z-index: ${props => props.$touchZIndex};
    width: ${props => props.$touchWidth};
    min-width: ${props => props.$touchMinWidth};
    max-width: ${props => props.$touchMaxWidth};
    height: ${props => props.$touchHeight};
    min-height: ${props => props.$touchMinHeight};
    max-height: ${props => props.$touchMaxHeight};
    margin: ${props => props.$touchMargin};
    margin-top: ${props => props.$touchMarginTop};
    margin-right: ${props => props.$touchMarginRight};
    margin-bottom: ${props => props.$touchMarginBottom};
    margin-left: ${props => props.$touchMarginLeft};
    padding: ${props => props.$touchPadding};
    padding-top: ${props => props.$touchPaddingTop};
    padding-right: ${props => props.$touchPaddingRight};
    padding-bottom: ${props => props.$touchPaddingBottom};
    padding-left: ${props => props.$touchPaddingLeft};
    overflow: ${props => props.$touchOverflow};
    background-color: ${props => props.$touchBackgroundColor};
  }
  ${maxMQ("mobile")} {
    display: ${props => props.$mobileDisplay};
    flex: ${props => props.$mobileFlex};
    flex-direction: ${props => props.$mobileFlexDirection};
    flex-wrap: ${props => props.$mobileFlexWrap};
    justify-content: ${props => props.$mobileJustifyContent};
    align-items: ${props => props.$mobileAlignItems};
    position: ${props => props.$mobilePosition};
    top: ${props => props.$mobileTop};
    right: ${props => props.$mobileRight};
    bottom: ${props => props.$mobileBottom};
    left: ${props => props.$mobileLeft};
    z-index: ${props => props.$mobileZIndex};
    width: ${props => props.$mobileWidth};
    min-width: ${props => props.$mobileMinWidth};
    max-width: ${props => props.$mobileMaxWidth};
    height: ${props => props.$mobileHeight};
    min-height: ${props => props.$mobileMinHeight};
    max-height: ${props => props.$mobileMaxHeight};
    margin: ${props => props.$mobileMargin};
    margin-top: ${props => props.$mobileMarginTop};
    margin-right: ${props => props.$mobileMarginRight};
    margin-bottom: ${props => props.$mobileMarginBottom};
    margin-left: ${props => props.$mobileMarginLeft};
    padding: ${props => props.$mobilePadding};
    padding-top: ${props => props.$mobilePaddingTop};
    padding-right: ${props => props.$mobilePaddingRight};
    padding-bottom: ${props => props.$mobilePaddingBottom};
    padding-left: ${props => props.$mobilePaddingLeft};
    overflow: ${props => props.$mobileOverflow};
    background-color: ${props => props.$mobileBackgroundColor};
  }
  ${minMQ("tablet")} {
    display: ${props => props.$tabletDisplay};
    flex: ${props => props.$tabletFlex};
    flex-direction: ${props => props.$tabletFlexDirection};
    flex-wrap: ${props => props.$tabletFlexWrap};
    justify-content: ${props => props.$tabletJustifyContent};
    align-items: ${props => props.$tabletAlignItems};
    position: ${props => props.$tabletPosition};
    top: ${props => props.$tabletTop};
    right: ${props => props.$tabletRight};
    bottom: ${props => props.$tabletBottom};
    left: ${props => props.$tabletLeft};
    z-index: ${props => props.$tabletZIndex};
    width: ${props => props.$tabletWidth};
    min-width: ${props => props.$tabletMinWidth};
    max-width: ${props => props.$tabletMaxWidth};
    height: ${props => props.$tabletHeight};
    min-height: ${props => props.$tabletMinHeight};
    max-height: ${props => props.$tabletMaxHeight};
    margin: ${props => props.$tabletMargin};
    margin-top: ${props => props.$tabletMarginTop};
    margin-right: ${props => props.$tabletMarginRight};
    margin-bottom: ${props => props.$tabletMarginBottom};
    margin-left: ${props => props.$tabletMarginLeft};
    padding: ${props => props.$tabletPadding};
    padding-top: ${props => props.$tabletPaddingTop};
    padding-right: ${props => props.$tabletPaddingRight};
    padding-bottom: ${props => props.$tabletPaddingBottom};
    padding-left: ${props => props.$tabletPaddingLeft};
    overflow: ${props => props.$tabletOverflow};
    background-color: ${props => props.$tabletBackgroundColor};
  }
  ${minMQ("desktop")} {
    display: ${props => props.$desktopDisplay};
    flex: ${props => props.$desktopFlex};
    flex-direction: ${props => props.$desktopFlexDirection};
    flex-wrap: ${props => props.$desktopFlexWrap};
    justify-content: ${props => props.$desktopJustifyContent};
    align-items: ${props => props.$desktopAlignItems};
    position: ${props => props.$desktopPosition};
    top: ${props => props.$desktopTop};
    right: ${props => props.$desktopRight};
    bottom: ${props => props.$desktopBottom};
    left: ${props => props.$desktopLeft};
    z-index: ${props => props.$desktopZIndex};
    width: ${props => props.$desktopWidth};
    min-width: ${props => props.$desktopMinWidth};
    max-width: ${props => props.$desktopMaxWidth};
    height: ${props => props.$desktopHeight};
    min-height: ${props => props.$desktopMinHeight};
    max-height: ${props => props.$desktopMaxHeight};
    margin: ${props => props.$desktopMargin};
    margin-top: ${props => props.$desktopMarginTop};
    margin-right: ${props => props.$desktopMarginRight};
    margin-bottom: ${props => props.$desktopMarginBottom};
    margin-left: ${props => props.$desktopMarginLeft};
    padding: ${props => props.$desktopPadding};
    padding-top: ${props => props.$desktopPaddingTop};
    padding-right: ${props => props.$desktopPaddingRight};
    padding-bottom: ${props => props.$desktopPaddingBottom};
    padding-left: ${props => props.$desktopPaddingLeft};
    overflow: ${props => props.$desktopOverflow};
    background-color: ${props => props.$desktopBackgroundColor};
  }
  ${minMQ("fullhd")} {
    display: ${props => props.$fullhdDisplay};
    flex: ${props => props.$fullhdFlex};
    flex-direction: ${props => props.$fullhdFlexDirection};
    flex-wrap: ${props => props.$fullhdFlexWrap};
    justify-content: ${props => props.$fullhdJustifyContent};
    align-items: ${props => props.$fullhdAlignItems};
    position: ${props => props.$fullhdPosition};
    top: ${props => props.$fullhdTop};
    right: ${props => props.$fullhdRight};
    bottom: ${props => props.$fullhdBottom};
    left: ${props => props.$fullhdLeft};
    z-index: ${props => props.$fullhdZIndex};
    width: ${props => props.$fullhdWidth};
    min-width: ${props => props.$fullhdMinWidth};
    max-width: ${props => props.$fullhdMaxWidth};
    height: ${props => props.$fullhdHeight};
    min-height: ${props => props.$fullhdMinHeight};
    max-height: ${props => props.$fullhdMaxHeight};
    margin: ${props => props.$fullhdMargin};
    margin-top: ${props => props.$fullhdMarginTop};
    margin-right: ${props => props.$fullhdMarginRight};
    margin-bottom: ${props => props.$fullhdMarginBottom};
    margin-left: ${props => props.$fullhdMarginLeft};
    padding: ${props => props.$fullhdPadding};
    padding-top: ${props => props.$fullhdPaddingTop};
    padding-right: ${props => props.$fullhdPaddingRight};
    padding-bottom: ${props => props.$fullhdPaddingBottom};
    padding-left: ${props => props.$fullhdPaddingLeft};
    overflow: ${props => props.$fullhdOverflow};
    background-color: ${props => props.$fullhdBackgroundColor};
  }
`

export const Div = styled.div`
  display: ${props => props.$allDisplay};
  flex: ${props => props.$allFlex};
  flex-direction: ${props => props.$allFlexDirection};
  flex-wrap: ${props => props.$allFlexWrap};
  justify-content: ${props => props.$allJustifyContent};
  align-items: ${props => props.$allAlignItems};
  position: ${props => props.$allPosition};
  top: ${props => props.$allTop};
  right: ${props => props.$allRight};
  bottom: ${props => props.$allBottom};
  left: ${props => props.$allLeft};
  z-index: ${props => props.$allZIndex};
  width: ${props => props.$allWidth};
  min-width: ${props => props.$allMinWidth};
  max-width: ${props => props.$allMaxWidth};
  height: ${props => props.$allHeight};
  min-height: ${props => props.$allMinHeight};
  max-height: ${props => props.$allMaxHeight};
  margin: ${props => props.$allMargin};
  margin-top: ${props => props.$allMarginTop};
  margin-right: ${props => props.$allMarginRight};
  margin-bottom: ${props => props.$allMarginBottom};
  margin-left: ${props => props.$allMarginLeft};
  padding: ${props => props.$allPadding};
  padding-top: ${props => props.$allPaddingTop};
  padding-right: ${props => props.$allPaddingRight};
  padding-bottom: ${props => props.$allPaddingBottom};
  padding-left: ${props => props.$allPaddingLeft};
  transform: ${props => props.$allTransform};
  opacity: ${props => props.$allOpacity};
  text-align: ${props => props.$allTextAlign};
  overflow: ${props => props.$allOverflow};
  color: ${props => props.$allColor};
  background-color: ${props => props.$allBackgroundColor};
  column-gap: ${props => props.$allColumnGap};
  row-gap: ${props => props.$allRowGap};
  aspect-ratio: ${props => props.$allAspectRatio};
  ${maxMQ("touch")} {
    display: ${props => props.$touchDisplay};
    flex: ${props => props.$touchFlex};
    flex-direction: ${props => props.$touchFlexDirection};
    flex-wrap: ${props => props.$touchFlexWrap};
    justify-content: ${props => props.$touchJustifyContent};
    align-items: ${props => props.$touchAlignItems};
    position: ${props => props.$touchPosition};
    top: ${props => props.$touchTop};
    right: ${props => props.$touchRight};
    bottom: ${props => props.$touchBottom};
    left: ${props => props.$touchLeft};
    z-index: ${props => props.$touchZIndex};
    width: ${props => props.$touchWidth};
    min-width: ${props => props.$touchMinWidth};
    max-width: ${props => props.$touchMaxWidth};
    height: ${props => props.$touchHeight};
    min-height: ${props => props.$touchMinHeight};
    max-height: ${props => props.$touchMaxHeight};
    margin: ${props => props.$touchMargin};
    margin-top: ${props => props.$touchMarginTop};
    margin-right: ${props => props.$touchMarginRight};
    margin-bottom: ${props => props.$touchMarginBottom};
    margin-left: ${props => props.$touchMarginLeft};
    padding: ${props => props.$touchPadding};
    padding-top: ${props => props.$touchPaddingTop};
    padding-right: ${props => props.$touchPaddingRight};
    padding-bottom: ${props => props.$touchPaddingBottom};
    padding-left: ${props => props.$touchPaddingLeft};
    transform: ${props => props.$touchTransform};
    opacity: ${props => props.$touchOpacity};
    text-align: ${props => props.$touchTextAlign};
    overflow: ${props => props.$touchOverflow};
    color: ${props => props.$touchColor};
    background-color: ${props => props.$touchBackgroundColor};
    column-gap: ${props => props.$touchColumnGap};
    row-gap: ${props => props.$touchRowGap};
    aspect-ratio: ${props => props.$touchAspectRatio};
  }
  ${maxMQ("mobile")} {
    display: ${props => props.$mobileDisplay};
    flex: ${props => props.$mobileFlex};
    flex-direction: ${props => props.$mobileFlexDirection};
    flex-wrap: ${props => props.$mobileFlexWrap};
    justify-content: ${props => props.$mobileJustifyContent};
    align-items: ${props => props.$mobileAlignItems};
    position: ${props => props.$mobilePosition};
    top: ${props => props.$mobileTop};
    right: ${props => props.$mobileRight};
    bottom: ${props => props.$mobileBottom};
    left: ${props => props.$mobileLeft};
    z-index: ${props => props.$mobileZIndex};
    width: ${props => props.$mobileWidth};
    min-width: ${props => props.$mobileMinWidth};
    max-width: ${props => props.$mobileMaxWidth};
    height: ${props => props.$mobileHeight};
    min-height: ${props => props.$mobileMinHeight};
    max-height: ${props => props.$mobileMaxHeight};
    margin: ${props => props.$mobileMargin};
    margin-top: ${props => props.$mobileMarginTop};
    margin-right: ${props => props.$mobileMarginRight};
    margin-bottom: ${props => props.$mobileMarginBottom};
    margin-left: ${props => props.$mobileMarginLeft};
    padding: ${props => props.$mobilePadding};
    padding-top: ${props => props.$mobilePaddingTop};
    padding-right: ${props => props.$mobilePaddingRight};
    padding-bottom: ${props => props.$mobilePaddingBottom};
    padding-left: ${props => props.$mobilePaddingLeft};
    transform: ${props => props.$mobileTransform};
    opacity: ${props => props.$mobileOpacity};
    text-align: ${props => props.$mobileTextAlign};
    overflow: ${props => props.$mobileOverflow};
    color: ${props => props.$mobileColor};
    background-color: ${props => props.$mobileBackgroundColor};
    column-gap: ${props => props.$mobileColumnGap};
    row-gap: ${props => props.$mobileRowGap};
    aspect-ratio: ${props => props.$mobileAspectRatio};
  }
  ${minMQ("tablet")} {
    display: ${props => props.$tabletDisplay};
    flex: ${props => props.$tabletFlex};
    flex-direction: ${props => props.$tabletFlexDirection};
    flex-wrap: ${props => props.$tabletFlexWrap};
    justify-content: ${props => props.$tabletJustifyContent};
    align-items: ${props => props.$tabletAlignItems};
    position: ${props => props.$tabletPosition};
    top: ${props => props.$tabletTop};
    right: ${props => props.$tabletRight};
    bottom: ${props => props.$tabletBottom};
    left: ${props => props.$tabletLeft};
    z-index: ${props => props.$tabletZIndex};
    width: ${props => props.$tabletWidth};
    min-width: ${props => props.$tabletMinWidth};
    max-width: ${props => props.$tabletMaxWidth};
    height: ${props => props.$tabletHeight};
    min-height: ${props => props.$tabletMinHeight};
    max-height: ${props => props.$tabletMaxHeight};
    margin: ${props => props.$tabletMargin};
    margin-top: ${props => props.$tabletMarginTop};
    margin-right: ${props => props.$tabletMarginRight};
    margin-bottom: ${props => props.$tabletMarginBottom};
    margin-left: ${props => props.$tabletMarginLeft};
    padding: ${props => props.$tabletPadding};
    padding-top: ${props => props.$tabletPaddingTop};
    padding-right: ${props => props.$tabletPaddingRight};
    padding-bottom: ${props => props.$tabletPaddingBottom};
    padding-left: ${props => props.$tabletPaddingLeft};
    transform: ${props => props.$tabletTransform};
    opacity: ${props => props.$tabletOpacity};
    text-align: ${props => props.$tabletTextAlign};
    overflow: ${props => props.$tabletOverflow};
    color: ${props => props.$tabletColor};
    background-color: ${props => props.$tabletBackgroundColor};
    column-gap: ${props => props.$tabletColumnGap};
    row-gap: ${props => props.$tabletRowGap};
    aspect-ratio: ${props => props.$tabletAspectRatio};
  }
  ${minMQ("desktop")} {
    display: ${props => props.$desktopDisplay};
    flex: ${props => props.$desktopFlex};
    flex-direction: ${props => props.$desktopFlexDirection};
    flex-wrap: ${props => props.$desktopFlexWrap};
    justify-content: ${props => props.$desktopJustifyContent};
    align-items: ${props => props.$desktopAlignItems};
    position: ${props => props.$desktopPosition};
    top: ${props => props.$desktopTop};
    right: ${props => props.$desktopRight};
    bottom: ${props => props.$desktopBottom};
    left: ${props => props.$desktopLeft};
    z-index: ${props => props.$desktopZIndex};
    width: ${props => props.$desktopWidth};
    min-width: ${props => props.$desktopMinWidth};
    max-width: ${props => props.$desktopMaxWidth};
    height: ${props => props.$desktopHeight};
    min-height: ${props => props.$desktopMinHeight};
    max-height: ${props => props.$desktopMaxHeight};
    margin: ${props => props.$desktopMargin};
    margin-top: ${props => props.$desktopMarginTop};
    margin-right: ${props => props.$desktopMarginRight};
    margin-bottom: ${props => props.$desktopMarginBottom};
    margin-left: ${props => props.$desktopMarginLeft};
    padding: ${props => props.$desktopPadding};
    padding-top: ${props => props.$desktopPaddingTop};
    padding-right: ${props => props.$desktopPaddingRight};
    padding-bottom: ${props => props.$desktopPaddingBottom};
    padding-left: ${props => props.$desktopPaddingLeft};
    transform: ${props => props.$desktopTransform};
    opacity: ${props => props.$desktopOpacity};
    text-align: ${props => props.$desktopTextAlign};
    overflow: ${props => props.$desktopOverflow};
    color: ${props => props.$desktopColor};
    background-color: ${props => props.$desktopBackgroundColor};
    column-gap: ${props => props.$desktopColumnGap};
    row-gap: ${props => props.$desktopRowGap};
    aspect-ratio: ${props => props.$desktopAspectRatio};
  }
  ${minMQ("fullhd")} {
    display: ${props => props.$fullhdDisplay};
    flex: ${props => props.$fullhdFlex};
    flex-direction: ${props => props.$fullhdFlexDirection};
    flex-wrap: ${props => props.$fullhdFlexWrap};
    justify-content: ${props => props.$fullhdJustifyContent};
    align-items: ${props => props.$fullhdAlignItems};
    position: ${props => props.$fullhdPosition};
    top: ${props => props.$fullhdTop};
    right: ${props => props.$fullhdRight};
    bottom: ${props => props.$fullhdBottom};
    left: ${props => props.$fullhdLeft};
    z-index: ${props => props.$fullhdZIndex};
    width: ${props => props.$fullhdWidth};
    min-width: ${props => props.$fullhdMinWidth};
    max-width: ${props => props.$fullhdMaxWidth};
    height: ${props => props.$fullhdHeight};
    min-height: ${props => props.$fullhdMinHeight};
    max-height: ${props => props.$fullhdMaxHeight};
    margin: ${props => props.$fullhdMargin};
    margin-top: ${props => props.$fullhdMarginTop};
    margin-right: ${props => props.$fullhdMarginRight};
    margin-bottom: ${props => props.$fullhdMarginBottom};
    margin-left: ${props => props.$fullhdMarginLeft};
    padding: ${props => props.$fullhdPadding};
    padding-top: ${props => props.$fullhdPaddingTop};
    padding-right: ${props => props.$fullhdPaddingRight};
    padding-bottom: ${props => props.$fullhdPaddingBottom};
    padding-left: ${props => props.$fullhdPaddingLeft};
    transform: ${props => props.$fullhdTransform};
    opacity: ${props => props.$fullhdOpacity};
    text-align: ${props => props.$fullhdTextAlign};
    overflow: ${props => props.$fullhdOverflow};
    color: ${props => props.$fullhdColor};
    background-color: ${props => props.$fullhdBackgroundColor};
    column-gap: ${props => props.$fullhdColumnGap};
    row-gap: ${props => props.$fullhdRowGap};
    aspect-ratio: ${props => props.$fullhdAspectRatio};
  }
`

export const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${props => props.$allColumnGap};
  row-gap: ${props => props.$allRowGap};
  ${maxMQ("touch")} {
    column-gap: ${props => props.$touchColumnGap};
    row-gap: ${props => props.$touchRowGap};
  }
  ${maxMQ("mobile")} {
    column-gap: ${props => props.$mobileColumnGap};
    row-gap: ${props => props.$mobileRowGap};
  }
  ${minMQ("tablet")} {
    column-gap: ${props => props.$tabletColumnGap};
    row-gap: ${props => props.$tabletRowGap};
  }
  ${minMQ("desktop")} {
    column-gap: ${props => props.$desktopColumnGap};
    row-gap: ${props => props.$desktopRowGap};
  }
`

export const Text = styled.div`
  font-size: ${props => props.$allFontSize};
  font-weight: ${props => props.$allFontWeight};
  text-align: ${props => props.$allTextAlign};
  text-transform: ${props => props.$allTextTransform};
  color: ${props => props.$allColor};
  ${maxMQ("touch")} {
    font-size: ${props => props.$touchFontSize};
    font-weight: ${props => props.$touchFontWeight};
    text-align: ${props => props.$touchTextAlign};
    text-transform: ${props => props.$touchTextTransform};
    color: ${props => props.$touchColor};
  }
  ${maxMQ("mobile")} {
    font-size: ${props => props.$mobileFontSize};
    font-weight: ${props => props.$mobileFontWeight};
    text-align: ${props => props.$mobileTextAlign};
    text-transform: ${props => props.$mobileTextTransform};
    color: ${props => props.$mobileColor};
  }
  ${minMQ("tablet")} {
    font-size: ${props => props.$tabletFontSize};
    font-weight: ${props => props.$tabletFontWeight};
    text-align: ${props => props.$tabletTextAlign};
    text-transform: ${props => props.$tabletTextTransform};
    color: ${props => props.$tabletColor};
  }
  ${minMQ("desktop")} {
    font-size: ${props => props.$desktopFontSize};
    font-weight: ${props => props.$desktopFontWeight};
    text-align: ${props => props.$desktopTextAlign};
    text-transform: ${props => props.$desktopTextTransform};
    color: ${props => props.$desktopColor};
  }
`
